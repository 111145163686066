import React, {useEffect} from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Disqus } from 'gatsby-plugin-disqus'
import Prism from "prismjs"

import Layout from '../components/layout'
import Sidebar from '../components/sidebar'
import SEO from '../components/seo'

const Blog = styled.div`
  grid-column: 6 / span 6;
  grid-row: 1 / 4;
  max-width: 100%;
  @media screen and (max-width: 767px){
    grid-column: 1 / span 4;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px){
    grid-column: 2 / span 6;
  }
  figure{
    text-align: center;
    border-radius: 3px;
    figcaption{
      font-size: 0.8125em;
      padding: 0.8rem;
      font-family: var(--f-primary);
      color: var(--c-grey);
      font-style: italic;
    }
  }
  h6{
    text-transform: uppercase;
  }
`
const SidebarCol = styled.div`
  grid-column: 2 / span 3;
  @media screen and  (max-width: 1023px){
		display: none;
	}
`
const PostDate = styled.h6`
    font-size: 0.75em;
    font-weight: normal;
    color: var(--c-grey);
    margin: 0;
`
const PostTitle = styled.h3`
    font-size: 2rem;
    margin: 0.5rem 0 2rem;
    @media screen and (max-width: 767px){
        font-size: 1.5rem;
    }
    a{
        color:black;
        text-decoration: none;
        transition: 300ms;
    }
`
const Content = styled.div`
  line-height: 1.5;
  color: rgba(0,0,0, 0.8);
  font-family: var(--f-secondary);
  font-size: 1.125em;
  a{
    color: var(--c-tertiary);
    &:hover{
      text-decoration: none;
    }
  }
  blockquote{
    background: rgba(0,0,0,0.01);
    border-left: 3px solid var(--c-primary);
    margin: 0 0 1rem;
    padding: 1.5rem;
  }
  img,
  figure{
    max-width: 100%;
    height: auto;
  }
`
const Back = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  button{
    display: inline-flex;
    border: none;
    height: 2.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    align-items: center;
    background: var(--c-secondary);
    border-radius: 2.5rem;
    color: var(--c-primary);
    text-decoration: none;
    font-weight: 500;
  }
`

const PostTemplate = ({data, location}) => {

    const post = data.wordpressPost;
    const siteData = data.site;
    let featuredImage = false;

    useEffect(() => {
      Prism.highlightAll();
    })
  
    if (post.featured_media && post.featured_media.source_url ) {
      featuredImage = post.featured_media.source_url;
    }

    let disqusConfig = {
      url: `${siteData.siteMetadata.siteUrl+post.slug}`,
      identifier: post.id,
      title: post.title,
    }

    return (
      <Layout>
        <SEO 
          pageTitle={post.title} 
        />
        <div className="container">
            <SidebarCol><Sidebar headingText="Think, write, repeat" /></SidebarCol>
            <Blog>
                <PostDate>{post.date}</PostDate>
                <PostTitle dangerouslySetInnerHTML={{ __html: post.title }} />
                {featuredImage &&
                    <img src={featuredImage} alt={post.title} className="featured-image" />
                }
                <Content dangerouslySetInnerHTML={{ __html: post.content }} />

                <Disqus config={disqusConfig} style={{ marginTop: '4rem' }} />

                <Back>
                  <button onClick={(e) => window.history.back()}>← Read another posts</button>
                </Back>         
            </Blog>
        </div>
      </Layout>
    )
  
  }
  
  export default PostTemplate
  
  export const pageQuery = graphql`
    query PostById($id: String!) {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
      wordpressPost(id: { eq: $id }) {
        date(formatString: "MMMM Do, YYYY")
        slug
        title
        id
        tags {
          name
        }
        featured_media {
          source_url
        }
        categories {
          name
          slug
        }
        content
        excerpt
      }
    }
  `

// Hook
/*
function useWindowSize() {
  const isClient = typeof window === 'object';
  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }
  const [windowSize, setWindowSize] = useState(getSize);
  useEffect(() => {
    if (!isClient) {
      return false;
    }
    function handleResize() {
      setWindowSize(getSize());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return windowSize;
}*/